import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, IArticle, TableComponent } from '../../../shared';
import { useArticlesList } from '../../hooks';
import { useCreateSupplierPayment } from '../../hooks/supplier-payment.hook';

interface Props {
    supplierId: string;
}
export const RegisterSupplierPayments: FC<Props> = ({ supplierId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedArticles, setSelectedArticles] = useState<string[]>([]);

    const { mutateAsync: registerPayment, isPending: registerPaymentIsLoading } = useCreateSupplierPayment();
    const { data: articles, isPending: articlesIsLoading } = useArticlesList({
        supplier: supplierId,
        paidToSupplier: false,
    });

    const sortedArticles = useMemo(() => articles?.data.sort(sortArticles) || [], [articles]);

    useEffect(() => {
        setSelectedArticles(articles?.data.filter(({ paidToDVC }) => paidToDVC).map(({ id }) => id) || []);
    }, [articles]);

    const columns: GridColDef[] = [
        {
            field: 'articleNumber',
            headerName: t('articleNumber'),
            width: 140,
            valueFormatter: (value, row) => `${row.consignment?.consignmentNumber}/${row.articleNumber}`,
        },
        { field: 'descriptionNl', headerName: t('description'), flex: 5, minWidth: 200 },
        { field: 'paidToDVC', headerName: t('paidToDVC'), valueFormatter: (value) => (value ? t('yes') : t('no')) },
    ];

    const onRegisterPayment = async () => {
        await registerPayment({ supplierId, articleIds: selectedArticles });
        setOpen(false);
    };

    const onClose = () => setOpen(false);
    return (
        <>
            <Button onClick={() => setOpen(true)} variant="outlined">
                {t('registerPaymentsToSupplier')}
            </Button>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
                <DialogTitleWithClose onClose={onClose}>{t('registerPaymentsToSupplier')}</DialogTitleWithClose>
                <DialogContent>
                    {
                        <TableComponent
                            checkboxSelection
                            rowSelectionModel={selectedArticles}
                            onRowSelectionModelChange={(ids) => setSelectedArticles(ids as string[])}
                            rows={sortedArticles}
                            columns={columns}
                            mode="client"
                            pageSizeOptions={[100]}
                            hideFooter={(articles?.data?.length || 0) <= 100}
                            pageSize={100}
                            loading={articlesIsLoading}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onRegisterPayment}
                        disabled={!selectedArticles.length || registerPaymentIsLoading}
                    >
                        {t('registerPayments')}
                    </Button>
                    <Button onClick={onClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const sortArticles = (a: IArticle, b: IArticle) => {
    if (a.paidToDVC != b.paidToDVC) {
        if (a.consignment.consignmentNumber !== b.consignment.consignmentNumber) {
            return a.consignment.consignmentNumber.toString().localeCompare(b.consignment.consignmentNumber.toString());
        } else {
            a.articleNumber.toString().localeCompare(b.articleNumber.toString());
        }
    }
    if (a.paidToDVC) return -1;
    else return 1;
};
